.tag {
    display: flex
}


.searchCrawler {

    display: flex;
    flex-direction: column;
    align-items: start;

    gap: 1rem;

    &-keywords {
        &-selected {
            display: flex;
        }
    }

    &-pincodes {
        &-selected {
            display: flex;
        }
    }
}