

  .modify-container {
    font-family: 'Inter', sans-serif;
  
  
  .title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;

    margin-bottom: 20px;
  }
  
  .brand-role-block {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .dropdown {
    padding: .5rem .5rem;
    font-size: .85rem;
    border-radius: .5rem;
    border: 1px solid #ccc;
    width: 150px;
    background-color: #D9D9D9;
    text-align: center;
  }
  
  .add-btn {
    display:inline-flex;
    color: #5D38E0;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .remove-btn-div-hidden{
    padding: 11px 15px;
  }
  .remove-btn-div{
    border: 1px solid #ccc;
    padding: .2rem;
    border-radius: .3rem;
  }
  .remove-btn {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 4px 8px;
    font-size: .7rem;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .remove-btn:hover {
    background-color: #e60000;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
  }
  
  .submit-btn, .cancel-btn {
    padding: .4rem .9rem;
    font-size: .85rem;
    border-radius: .5rem;
    cursor: pointer;
    border: none;
  }
  
  .submit-btn {
    background-color: #5D38E0;
    color: white;
  }
  
  .submit-btn:hover {
    background-color: #5634cd;
  }
  
  .cancel-btn {
    background-color: #fff;
    color: #5D38E0;
    border: 1px solid #5D38E0;
    border-color: #5D38E0;
  }
  
  .cancel-btn:hover {
    background-color: #e9e9e9;
  }



  
  

}


.request-card{
  background-color: #fff;
  padding: .5rem .5rem;
  border-radius: .5rem;
  min-height: 2rem;
  max-height: 12rem;
  overflow: auto;
}