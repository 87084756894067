.mode-toggle {
    width: 30px;
    height: 30px;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.mode-dropdown {
    position: fixed;
    background: white;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 6px;
    z-index: 1000;
    padding: 8px;
}


.custom-button {
    background-color: rgb(100, 100, 205);
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: .5rem;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease-in-out;
  }
  
  .custom-button:hover {
    background-color: #00a3a3;
  }