@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&family=Poppins:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Inter:wght@300;400;500;700&display=swap');

.poppins-light {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-bold {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.login {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  font-family: 'Inter', sans-serif;
  position: relative;

  &-label {
    color: #384D6C;
  }

  &-input {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #384D6C;
    padding: .25rem 1rem;
  }

  &-left {
    flex: .5;
    width: 50%;

  }
  // .login-left-signup-container{
  //   overflow: auto;
  // }

  &-right {
    // flex: 1;
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: #F7F5FD;

    .right-text {
      margin-left: 2rem;
      margin-top: 2rem;
      width: 70%;
    }

    .main-enlytical-img-container {
      // display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   overflow: hidden;
      //   background: #F7F5FD;

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; // Prevents overflow
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;

      .main-enlytical-img {
        width: 100%;
        // height: 100%;
        height: auto;
        border-radius: .5rem;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        max-width: 100%; // Ensures image does not exceed container width
        height: auto; // Keeps aspect ratio
        border-radius: 0.5rem;
        object-fit: contain;
      }
    }
  }

  &-loginContainer {
    margin: 3rem 6rem;
    background-color: #fff;
    padding: 0rem 1rem;
    border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .Small-login-text {
      font-size: .7rem;
      color: #929292;
    }
  }

  &-loginContainerHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      color: #000000;
      font-weight: 600;
    }

    h6 {
      color: #8692A6;
      font-weight: 400;
    }
  }

  &-btn {
    border: none;
    color: #fff;
    background-color: var(--active-color);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    font-weight: 700;
  }

  #signup-btn {
    margin-top: 0rem;
    padding: .7rem 1.3rem;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
      color: #000000;
    }
  }
}





.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  position: relative;




  &-label {
    color: #384D6C;
  }

  &-input {
    background-color: #fff;
    border-radius: 1rem;
    border: 1px solid #384D6C;
    padding: .5rem 1rem;
    width: 100%;
  }

  &-left {
    display: flex;
    justify-content: center;
    flex: .5;
    width: 50%;
  }

  &-right {
    overflow: hidden;
    flex: 1;
    width: 50%;
    // height: 100vh;
    height: calc(100vh - 1rem);
    margin-top: 1rem;
    border-radius: .1rem;
    background-color: #F7F5FD;

    .right-content {
      display: flex;
      padding: 0rem 1rem;
      padding-top: 1rem;
      flex-direction: column;
      gap: 2rem;
    }

    .right-text {
      margin-left: 2rem;
      margin-top: 2rem;
      width: 70%;
    }
  }

  &-loginContainer {
    min-height: 100vh;
    height: 100vh;
    margin: 0rem;
    background-color: #fff;
    padding: 0rem 1rem;
    padding-right: 1rem;
    border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    // max-width: 400px;
    width: 25rem;


    display: flex;
    flex-direction: column;
  }

  &-loginContainerHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .2rem;
    margin-bottom: .5rem;

    h4 {
      color: #000000;
      font-weight: 600;
    }

    h6 {
      color: #8692A6;
      font-size: .85rem;
      font-weight: 400;
    }
  }

  .mb-3 {

    font-size: .85rem;

    input {
      font-size: .85rem;
    }
  }

  .signup-input {
    font-size: .75rem;

    input {
      font-size: .75rem;
    }
  }

  &-btn {
    border: none;
    width: 100%;
    color: #fff;
    //   background-color: var(--active-color);
    background-color: #5D38E0;
    border-radius: 1rem;
    padding: 0.75rem 1rem;
    font-size: .85rem;
    font-weight: 700;
    margin-top: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      // background-color: darken(var(--active-color), 10%);
      // background-color: rgba(var(--active-color), 0.9);
      // opacity: 0.9;
      background-color: darken(#5D38E0, 10%);

    }
  }

  .back-btn button {
    border: none;
    width: 25%;
    color: #5D38E0;

    //   background-color: var(--active-color);
    background-color: #fff;
    border: 1px solid #5D38E0;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    font-size: .85rem;
    font-weight: 700;
    margin-top: 10rem;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background-color: darken(#5D38E0, 10%);
      color: #fff;

    }
  }

  .input-labels {
    // display: flex;
    // flex-direction: column;
    // margin-bottom: .5rem;
    // gap: .2rem;
    font-size: .85rem;

    input {
      font-size: .85rem;
    }
  }

  .forgot-entries {
    font-size: .85rem;
    color: #696F79;

    input {
      font-size: .85rem;
    }
  }

  &-remember-forgot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    font-size: 0.85rem;

    .login-checkbox {
      display: flex;
      color: #696F79;
      align-items: center;
      gap: 5px;
    }

    .login-forgot {
      color: var(--active-color);
      font-weight: 600;
      text-decoration: none;
    }
  }

  &-signup-text {
    text-align: center;
    color: #8692A6;
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }

  &-signup {
    display: block;
    text-align: center;
    font-size: .95rem;
    font-weight: 600;
    color: var(--active-color);
    color: #5D38E0;
    text-decoration: none;
    //   margin-bottom: 1rem;
  }

  &-divider {
    text-align: center;
    margin: .5rem 0;
    position: relative;
    font-size: 0.75rem;
    color: #8692A6;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 40%;
      height: 1px;
      background: #ddd;
      top: 50%;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  //otp logic

  .otp-text {
    flex: 1;
    text-align: left;
    font-size: 15px;
  }

  .resend-otp-btn {
    color: var(--active-color);
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s ease-in-out;

    &:hover {
      // color: darken(var(--active-color), 10%);
      text-decoration: underline;
    }
  }

  .fetching-otp {
    color: #ff6b6b;
    font-weight: 600;
    margin-left: 10px;
  }

  .resend-otp-btn {
    color: var(--active-color);
    font-weight: 600;
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: rgba(var(--active-color), 0.8);
      /* Alternative: reduce opacity */
      text-decoration: underline;
    }
  }



  &-sso {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &-sso-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: none;
    border:1px solid #5D38E0;
    border-radius: 1rem;
    padding: 0.75rem 1rem;
    font-size: .85rem;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;

    .google-icon{
      display: flex;
      margin-left: .5rem;
      align-items: flex-start;
    }
    .microsoft-icon{
      display: flex;
      margin-left: .5rem;
      align-items: flex-start;
    }

    i {
      display: flex;
      margin-left: .5rem;
      align-items: flex-start;
    }

    div {
      display: flex;
      justify-content: center;
    }

    &.google {
      // background-color: #db4437;
      background-color: #fff;
      // color: #fff;
      color:#242424;

      &:hover {
        // background-color: darken(#db4437, 10%);
        background-color: #eae9e9;
      }
    }

    &.microsoft {
      // background-color: #0078d4;
      background-color: #fff;

      // color: #fff;
      color:#242424;

      &:hover {
        // background-color: darken(#0078d4, 10%);
        background-color: #eae9e9;
      }
    }
  }

  .password-toggle {
    position: absolute;
    right: 10px;
    top: 70%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #384D6C;
  }


}





.resendOtpBtn {
  color: #2a3444;
  text-decoration: underline;
  cursor: pointer;
}








// signup mode css

.account-selection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 2rem;
  font-size: .75rem;
}

.account-card {
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: 1rem;
  border: 1px solid #D9D9D9;
  background: #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  // width: 320px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &:hover,
  &.selected {
    border-color: var(#5D38E0);
    border: 1px solid #5D38E0;
    background-color: #F7F5FD;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
  }
}

.icon-container {
  font-size: 1.8rem;
  // color: var(--active-color);
  color: #5D38E0;
  margin-right: 1rem;
  padding: 1rem;

}

.account-details {
  display: flex;
  flex-direction: column;



  .account-description {
    color: #8692A6;
  }


  .account-header {
    font-size: .85rem;
    font-weight: 500;
    color: #000000;
  }

}

.already-account-block {

  margin-top: 8rem;
  font-size: .8rem;
  color: #8692A6;

  span {
    color: #5D38E0;
    cursor: pointer;

    :hover {
      color: #77849b;
    }
  }
}




// user profile image upload 


.profile-upload-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  .image-preview {
    width: 5rem;
    height: 5rem;
    border: 2px dashed #979797;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #F7F5FD;
    transition: all 0.3s ease-in-out;

    &:hover {
      border-color: var(--active-color);
    }

    .profile-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile-image-before {
      opacity: (0.3);
    }



    .placeholder {
      font-size: 24px;
      color: #888;
    }
  }

  .upload-controls {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .profile-text {
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }

    .file-input {
      display: none;
    }

    label {
      border: 1px solid #A9A9A9;
      color: #5D38E0;
      padding: 6px 12px;
      border-radius: .5rem;
      display: flex;
      gap: .5rem;
      cursor: pointer;


      :hover {
        opacity: 0.9;
        color: darken(#000000, 10%);
        color: #000000;

      }


      .upload-button {
        background-color: var(--active-color);
        display: inline-block;
        background-color: #fff;
        color: #5D38E0;
        padding: 6px 12px;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        width: fit-content;
      }

      .upload-button:hover {
        // background-color: darken(var(--active-color), 10%);
        // background-color: rgba(var(--active-color), 0.9); // Adjust opacity instead of darkening
        opacity: (0.9);

      }
    }

    .remove-button {
      background-color: #ff4d4d;
      color: #fff;
      padding: 6px 12px;
      border: none;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      width: fit-content;
    }

    .remove-button:hover {
      background-color: darken(#ff4d4d, 10%);
    }

    .file-info {
      font-size: 12px;
      color: #888;
    }
  }
}



//agency-brand-container

.agency-brand-container {

  border: 2px dashed #000000;
  border-radius: .5rem;
  display: flex;
  padding: 0.5rem 1rem;
  // padding-top: 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  margin: .5rem 0rem;
  background-color: #F7F5FD;
  width: 100%;


  .MainImage-preview {
    // width: 20rem;
    max-width: 100%; // Responsive width
    max-height: 7rem; // Adjust as needed
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #F7F5FD;
    transition: all 0.3s ease-in-out;
    padding: 0.5rem;
    border-radius: 0.5rem;

    .profile-image {
      // width: 100%;
      // height: 100%;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      // object-fit: cover;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  label {
    border: 1px solid #A9A9A9;
    color: #9D9D9D;
    margin-top: .2rem;
    padding: 6px 12px;
    border-radius: .5rem;
    display: flex;
    gap: .5rem;
    cursor: pointer;
    background-color: #fff;


    :hover {
      opacity: 0.9;
      color: darken(#000000, 10%);
      color: #000000;

    }


    .upload-button {
      background-color: var(--active-color);
      display: inline-block;
      background-color: #fff;
      color: #5D38E0;
      padding: 6px 12px;
      border: none;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      text-align: center;
      width: fit-content;
    }

    .upload-button:hover {
      // background-color: darken(var(--active-color), 10%);
      // background-color: rgba(var(--active-color), 0.9); // Adjust opacity instead of darkening
      opacity: (0.9);

    }
  }

  .icon {
    i {
      font-size: 1.5rem;
    }
  }

  .div1 {
    font-weight: 600;
  }

  .div2 {
    color: #9D9D9D;
    font-size: .55rem;

  }

  .div3 {
    color: #9D9D9D;
    font-size: .55rem;
  }

}



//checkbox-block

.check-box-block {
  display: flex;
  // flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  font-size: 0.75rem;
  color: #333;

  .terms-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;

    input {
      accent-color: #007bff; // Blue checkbox
      width: 1rem;
      height: 1rem;
    }

    .terms-link {
      color: #007bff;
      text-decoration: none;
      font-weight: 600;
    }

    .terms-link:hover {
      text-decoration: underline;
    }
  }

  .captcha-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}










// signup otp container



.otp-container {
  // margin-top: 6rem;
  text-align: center;
  max-width: 400px;
  margin: auto;

  .otp-message {
    font-size: .85rem;
    margin-bottom: 1rem;
    color: #1E1E1E;
  }

  .otp-input-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .otp-input {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.2rem;
    text-align: center;
    border: 1px solid #8692A6;
    border-radius: .5rem;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }

    &.success {
      background-color: #EDFFF2 !important;
      border-color: #5FB100;
      color: #5FB100;
    }

    &.error {
      // background-color: #dc3545 !important;
      background-color: #E500001A;
      border-color: #E50000;
      color: #E50000;
    }
  }

  .otp-prompt {
    font-size: 0.85rem;
    margin-bottom: 1rem;
    color: #555;
  }

  .otp-status {
    font-size: .85rem;
    margin-bottom: 1rem;
  }

  .otp-success {
    color: #5FB100;
  }

  .otp-fail {
    color: #E50000;
  }

  .resend-otp {
    font-size: 0.85rem;
    color: #1E1E1E;

    span {
      cursor: pointer;
      font-weight: normal;
      color: #0083D4;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}



.loader2 {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}