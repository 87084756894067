.audience-container {
    padding: 1rem;
    background-color: #fff;
    height: auto;
    min-height: 100vh;
    border-radius: 1rem;

    .audience-header{
        display: flex;
        gap: .5rem;
        color: #1E1E1E;
        margin-bottom: .5rem;
      }


      /* Center the query text */
.choose-query {
    margin-top: 1rem;
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    text-align: center;
    font-weight: bold;
}

/* Box container styling */
.box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem; /* Add space between rows */
}

/* Styling individual rows (box groups) */
.boxes {
    
    display: flex;
    flex-direction: column;
    width: 45%; 
    width: calc(50% - 1rem); /* Account for the gap */
    padding: .9rem;
    border: 1px solid #ccc; /* No visible border */
    border-radius: 8px;
    text-align: left;
    box-sizing: border-box;

    &:hover{
        background-color: #ebebeb;
        .box1{
            text-decoration: underline;
        }
    }

}

/* Styling the text inside Box 1 */
.box1 {
    font-size: 1rem;
    // font-weight: bold;
    color: #0065AF; /* Blue color for clickable text */
    cursor: pointer;
    margin-bottom: 0.5rem;
    
}
.box1:hover{
    opacity: (0.9);
}


/* Styling the text inside Box 2 */
.box2 {
    font-size: .75rem;
    color: #1E1E1E;
}

/* End-bottom text styling */
.end-bottom {
    margin-left: 1rem;
    margin-top: 2rem;
    font-size: 1rem;
    text-align:left
}
.click-here-box{
    margin-left: .3rem;
}

.end-bottom span {
    color: #356B8D; /* Blue color for clickable text */
    cursor: pointer;
    text-decoration: underline;
}


}










// history

.audience-history-container{
    padding: 1rem;
    background-color: #fff;
    height: auto;
    min-height: 100vh;
    border-radius: 1rem;

    .audience-history-header{
        display: flex;
        gap: .5rem;
        color: #1E1E1E;
        margin-bottom: .7rem;
      }

    .create-block{
        margin-bottom: 1.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;
      }

    .search-box{
        border: 1px solid #CDCDCD;
        padding: .5rem;
        border-radius: 1rem;
        input{
            border: none;
            outline: none;
            font-size: .9rem;
        }
        i{
            color: #CDCDCD;
            margin-right: .2rem;
        }
    }  

      .create-audience {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        background-color: #356B8D;
        color: white;
        border: none;
        padding: 0.6rem 1rem;
        gap: .3rem;
        border-radius: 1rem;
        cursor: pointer;
        font-size: 0.9rem;

        i{
            font-weight:bolder;
        }
      
        &:hover {
          opacity: 0.9;
        }
      } 
      
      
      div.p-paginator-bottom.p-paginator.p-component{
        display: flex;
        justify-content: flex-end;

      }

}

















// audience query create audience
.audience-query-container{
    padding: 1rem;
    background-color: #fff;
    height: auto;
    min-height: 100vh;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    font-size: .85rem;
    font-weight: 500;

    .audience-query-header{
        display: flex;
        gap: .5rem;
        color: #1E1E1E;
        margin-bottom: .7rem;
      }


      .box1 {
        font-size: .9rem;
        margin-top: 1rem;
        // font-weight: bold;
        color: #0065AF; /* Blue color for clickable text */
        cursor: pointer;
        margin-bottom: 0.3rem;
        font-weight: bold;
        .tex{
            color: #1E1E1E;
        }
        
    }

    .box2 {
        font-size: .7rem;
        color: #7F7F7F;
        margin-bottom: 1rem;
    }

    .field-container{
        display: flex;
        align-items: center;
        margin: 1rem;
        gap: .5rem;
        .background-box{
            cursor: pointer;
            display: flex;
            position: relative;
            gap: .2rem;
            color: #707070;
            border-radius: 1rem;
            background-color: #F4F4F4;
            padding: .7rem;
            .error-box{
                border: 1px solid red;
                border-color: red;
            }
        }
    }
    .left-field-box{
        display: flex;
        width: 19%;
        justify-content: center;
        // justify-content: flex-end;
    }

    .flex-convert-box{
        display: flex;
        flex-direction: column;
    }

    .char-counter{
        display: flex;
        margin-left: .2rem;
        align-items: baseline;
        color: #707070;
        font-size: .65rem;
    }



    .box-contain1{
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
  

        div {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          input{
            cursor: pointer;
         }
        }
      }

    .input-name-box{
        border:1px solid #ccc;
        padding: .5rem;
        border-radius: 1rem;
        flex: 1;
        max-width: 50%; 
        min-width: 200px;
        input{
            width: -webkit-fill-available;
            border: none;
            outline: none;
        }
    }

    .description-textarea-box{
        border:1px solid #ccc;
        padding: .5rem;
        border-radius: 1rem;
        width: 50%;
        textarea{
            width: -webkit-fill-available;
            border: none;
            outline: none;
        }
    }

    .asin-include-textarea-box{
        border:1px solid #ccc;
        padding: .5rem;
        border-radius: 1rem;
        width: 65%;
        textarea{
            width: -webkit-fill-available;
            border: none;
            outline: none;    
            font-size: .85rem;
        }
    }
    



    .end-box{
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        margin-bottom: 1rem;
        align-items: center;
    }
    .end-bottom {
        margin-left: 1rem;
        font-size: 1rem;
        text-align:left
    }
    .click-here-box{
        margin-left: .3rem;
    }
    
    .end-bottom span {
        color: #356B8D; /* Blue color for clickable text */
        cursor: pointer;
        text-decoration: underline;
    }

    .create-audience {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        margin-right: 2rem;
        background-color: #356B8D;
        color: white;
        border: none;
        padding: 0.6rem 1rem;
        gap: .3rem;
        border-radius: 1rem;
        cursor: pointer;
        font-size: 0.9rem;

        i{
            font-weight:bolder;
        }
      
        &:hover {
          opacity: 0.9;
        }
      } 

}




  
  .dropdown-card {
    position: absolute;
    margin-top: 0.1rem;
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: .5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 250px;
    z-index: 1000;
  }
  
  .search-input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .options-container {
    max-height: 200px;
    overflow-y: auto;
  }
  
  .option {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }
  
  .no-options {
    text-align: center;
    color: gray;
    padding: 0.5rem;
  }
  

  .error-message {
    color: red;
    font-size: 0.85rem;
    margin-top: 0.5rem;
  }
  

.single-line-box{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}
.seperate-line-box{
    width: 70%;
}

.text-gray{
    color: #7F7F7F;
    font-size: .75rem;
}

.flex-box{
    display: flex;
    flex-direction: column;
}




// custom toast css

.Toastify__toast {
    background-color: #e9f7ef !important; /* Light green background */
    color: #155724 !important; /* Dark green text */
    border: 1px solid #c3e6cb !important;
    border-radius: 8px !important;
    font-family: 'Arial', sans-serif;
  }
  
  .Toastify__toast-icon {
    margin-right: 10px;
  }



  .preset-buttons {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    border-radius: 1rem;
    color: #ccc;
  }
  



  .date-range-selector {
    position: relative;
    display: inline-block;
  }
  
  .background-box {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
  
  .card-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 45rem;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    z-index: 1000;
  }
  
  .preset-list {
    flex: 1;
    font-size: .75rem;
    padding: 10px;
    border-right: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .calendar-container {
    flex: 2;
    padding: 10px;
  }
  
  .preset-list .days-block {
    font-size: .7rem;
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
  }
  
  .preset-list .days-block:hover {
    background: #f0f0f0;
    border-radius: 1rem;
  }











  //calendar custome csss

  .calendar-container {
    position: relative;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 80rem;
    margin: 0 auto;

  }

  
  
  .p-datepicker {
    width: 100%; /* Full-width calendar */
    max-width: 80rem; /* Maximum width */
    width: 34rem;
  }
  
  .p-datepicker-header {
    background-color: #f5f5f5; /* Header background color */
  }
  .p-datepicker-group{
    width: 20rem;

    td{
      font-size: .75rem;
      padding: .3rem;

      span{
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0%;
        // border: 1px solid blue;
        
      }
    }
    th{
      font-size: .75rem;
      padding: .3rem;

      span{
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 0%;
        // border: 1px solid blue;
        
      }
    }

  }

  .p-datepicker-buttonbar{
    padding: .5rem;
    .p-button{
      font-size: .75rem;
    }
  }
  
  .p-datepicker-today {
    font-weight: bold;
    color: #007ad9; /* Custom today button color */
  }
  
  .p-datepicker-day {
    font-size: 14px;
    padding: 0.5rem;
  }
  
  .p-button {
    background-color: #007ad9;
    border: none;
    color: #fff;
    border-radius: 1rem;
  }



  // .p-datepicker{

  // }

  // #pr_id_5_panel{

  // }

  // .p-datepicker p-component p-datepicker-inline p-datepicker-multiple-month p-ripple-disabled{

  // }

  // .tbody, td, tfoot, th, thead, tr{

  // }



  
  