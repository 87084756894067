.selection-screen-container {
    width: 100%;
    height: auto;
    min-height: 100vh;
    margin: 20px auto;
    padding: 20px;
    // border: 1px solid #ccc;
    background-color: #FFFFFF;
    border-radius: 8px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;




    .header{
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 1.5rem;
    }


    .dashboard-card-container{
        display: flex;
        gap: 1rem;
        flex-wrap:wrap ;
        // justify-content: space-between;

    .card{
        width: 28%;
        width: calc(25% - 0rem);
        min-width: 24%;
        display: flex;
        flex-direction: column;
        border-radius: .8rem;
        padding: .5rem;

        .card-heading{
            font-size: .85rem;
            display: flex;
            gap: .5rem;
            font-weight: 500;
            margin: 0rem .5rem;

        }

        .card-logo {
            width: 24px;  /* Adjust size as needed */
            height: 24px;
          }

        .card-description{

            display: flex;
            border: 1px solid #ccc;
            padding: .5rem;
            font-size: .75rem;
            margin: .5rem .5rem;
            border-radius: .8rem;
            margin-bottom: .8rem;
            min-height: 4rem;

        }

        .card-button-container{
            font-size: .75rem;
            display: flex;
            justify-content: space-between;
            padding: 0rem .5rem;



            .view-button-link{
                color: #959595;
                &:hover{
                    text-decoration: underline;
                    cursor: pointer;
                    color: #5D38E0;
                }
            }

            .toggle-btn {
                width: 40px;
                height: 20px;
                background: #A9A9A9;
                border-radius: 20px;
                position: relative;
                cursor: pointer;
                transition: background 0.3s;
              }
              
              .toggle-btn::before {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                background: white;
                border-radius: 50%;
                top: 50%;
                left: 2px;
                transform: translateY(-50%);
                transition: left 0.3s;
              }
              
              .toggle-btn.active {
                background: #5D38E0;
              }
              
              .toggle-btn.active::before {
                left: 22px;
              }

        }
    }

}





   .save-btn{
    font-size: .7rem;
    padding: .5rem .9rem;
    display:inline-flex;
    width: 20%;
    background-color: #5D38E0;
    margin-top: 2rem;
    border-radius: .5rem;
    color: #fff;
    cursor: pointer;


    &:hover{
        opacity: 0.9;
    }



   }





  }